import React, { Component, useState, useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';


const ClientFetchingExample = props => {

  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();



  const [books, setBooks] = useState({
    loading: true,
    response: null,
  });

  const [images, setImages] = useState({
    loadingImages: true,
    imageResponse: null,
  });

  useEffect(() => {
    // TODO
    instaImages().then(data => {
      setImages({
        loadingImages: false,
        imageResponse: data,
      })
    });
    

  }, [])

  const { loading, response } = books;

  const { loadingImages, imageResponse } = images;

  return (
    <Layout title={siteTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page>
      {/* {loading && 'Loading...'}
      {JSON.stringify(response.review, null, 4)} */}

      <h2 className="pageHeader">Instagram Doodles</h2>

        <p>Some recent Instagram illustration posts.</p>
        <span>From <a href="https://www.instagram.com/raudaschl/" target="_blank">Instagram</a></span>

      {loadingImages ? (
              <div >
                <p>Please hold, scraping Instagram API!</p>
                <ImageLoadingAni></ImageLoadingAni>
              </div>
            ) : imageResponse.illustrations ? (
              
              <div className="cards">
                <ul style={{"list-style-type": "none", "padding": 0}}>
                {
                  imageResponse.illustrations.map((el, index) => {
                    return (
                      <li key={imageResponse.illustrations[index].date}>

                      <div className="card">
                        <img className="fullImage" src={imageResponse.illustrations[index].url} alt={``} style={{ width: "100%" }} />
                          <div className="card-container">
                            
                          {imageResponse.illustrations[index].description != null ? <h4><b>{imageResponse.illustrations[index].description}</b></h4> : <></>}
                            
                            <p>{imageResponse.illustrations[index].prettyDate} | {imageResponse.illustrations[index].location != null ? <>{imageResponse.illustrations[index].location.name} | </> : <></>} {imageResponse.illustrations[index].likes} <Heart></Heart></p>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
                </ul>  
              </div>
              
            ) : (
              <p>Oh noes, error fetching instagram images :(</p>
            )}
      
      </Page>
    </Layout>
  )
}



const instaImages = () => {
  return axios
  .get(`https://www.mocky.io/v2/5e120844310000cc4d593fd1`)
  .then(response => {
    console.log(response.data)
    return response.data;
  })
  .catch(console.error)
};



function ImageLoadingAni() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{ margin: "auto", background: "#fff" }}
    >
      <g transform="translate(50 50) scale(.8) translate(-50 -50) rotate(186.979 50 50)">
        <animateTransform
          attributeName="transform"
          calcMode="spline"
          dur="1s"
          keySplines="0.7 0 0.3 1;0.7 0 0.3 1;0.7 0 0.3 1"
          keyTimes="0;0.333;0.667;1"
          repeatCount="indefinite"
          type="rotate"
          values="360 50 50;240 50 50;120 50 50;0 50 50"
        ></animateTransform>
        <path
          fill="#93dbe9"
          d="M54.3 28.1h34.2C84 18.8 76.1 11.4 66.6 7.3L45.7 28.1h8.6z"
        ></path>
        <path
          fill="#689cc5"
          d="M61.7 7.3c-9.8-3.3-20.6-3.1-30.2.8v29.5l6.1-6.1L61.7 7.3z"
        ></path>
        <path
          fill="#5e6fa3"
          d="M28.1 11.6C18.8 16.1 11.4 24 7.3 33.5l20.8 20.8V11.6z"
        ></path>
        <path
          fill="#3b4368"
          d="M31.5 62.4L7.3 38.3c-3.3 9.8-3.1 20.6.8 30.2h29.5l-6.1-6.1z"
        ></path>
        <path
          fill="#93dbe9"
          d="M45.7 71.9H11.5c4.5 9.3 12.4 16.7 21.9 20.8l20.8-20.8h-8.5z"
        ></path>
        <path
          fill="#689cc5"
          d="M62.4 68.5L38.3 92.6c9.8 3.4 20.6 3.1 30.2-.8V62.4l-6.1 6.1z"
        ></path>
        <path
          fill="#5e6fa3"
          d="M71.9 45.7V88.5c9.3-4.5 16.7-12.4 20.8-21.9L71.9 45.7z"
        ></path>
        <path
          fill="#3b4368"
          d="M91.9 31.5H62.4l6.1 6.1 24.1 24.1c3.4-9.8 3.2-20.6-.7-30.2z"
        ></path>
      </g>
    </svg>
  );
}

function Heart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{ margin: "auto", background: "rgba(255, 255, 255, 0)", display: "inline" }}
    >
      <g transform="translate(50 62) scale(2)">
      <path
        fill="#e90c59"
        d="M27.745-23.382a17.034 17.034 0 00-24.132 0L0-19.77l-3.613-3.613a17.034 17.034 0 00-24.132 0 17.034 17.034 0 000 24.132l3.613 3.613 8.453 8.385L0 28.427l15.68-15.68 8.452-8.452L27.745.682c6.613-6.681 6.613-17.452 0-24.064z"
      >
        <animateTransform
          attributeName="transform"
          calcMode="spline"
          dur="1s"
          keySplines="0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1;0.215 0.61,0.355 1"
          keyTimes="0;0.05;0.39;0.45;0.6;1"
          repeatCount="indefinite"
          type="scale"
          values="0.68;0.8;0.6000000000000001;0.7200000000000001;0.68;0.6400000000000001"
        ></animateTransform>
      </path>
      </g>
    </svg>
  );
}

export default ClientFetchingExample